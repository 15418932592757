<section >
  <div class="container">
    <div class="row layer-fly-one">
      <div class="col-md-12">
        <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
      </div>
    </div>
    <div class="xamin-recentblog v1">
      <app-blog-owl></app-blog-owl>
    </div>
  </div>
</section>
