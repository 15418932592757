<!-- Footer -->
<footer class="footer-one iq-bg-dark iq-over-dark-90" id="contact">
  <div class="container">
    <!-- Address -->
    <div class="footer-top">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 ">
          <p class="footer-logo"><img alt="img" src="{{ logoImg }}" width="260"></p>
          <p class="footer-logo">To bring positive impact in society by making every building Zero Grid Energy for Empowering Growth & Self sustenance.</p>
          <ul class="info-share d-inline list-inline">
            <li class="list-inline-item"><a href="https://www.youtube.com/channel/UCckOWUC8Odh90Gi0d9W5gPw" target="_blank"><i class="fa fa-youtube-play fa-lg"></i></a></li>
            <li class="list-inline-item"><a href="https://www.facebook.com/arka11renewablesolutions" target="_blank"><i class="fa fa-facebook-f fa-lg"></i></a></li>
            <li class="list-inline-item"><a href="https://twitter.com/arka11_rs" target="_blank"><i class="fa fa-twitter fa-lg"></i></a></li>
            <li class="list-inline-item"><a href="https://instagram.com/arka11rs" target="_blank"><i class="fa fa-instagram fa-lg"></i></a></li>
            <li class="list-inline-item"><a href="https://www.linkedin.com/company/arka11-renewable-solutions" target="_blank"><i class="fa fa-linkedin fa-lg"></i></a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6 mt-4 mt-lg-0 mt-md-0">
          <h4 class="footer-title">Menu</h4>
          <div class="menu-footer-menu-container">
            <ul class="menu">
              <li class="menu-item"><a href="" routerLink="/"  aria-current="page">Home</a></li>
              <li class="menu-item"><a href="" routerLink="/about-us">About Us</a></li>
              <li class="menu-item"><a href="" routerLink="/services">Services</a></li>
              <li class="menu-item"><a href="" routerLink="/channel-partner">Become Partner</a></li>
              <li class="menu-item"><a href="" routerLink="/solar-calculator">Solar Calculator</a></li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-lg-2 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <h4 class="footer-title iq-rmt-30">About us</h4>
          <div class="menu-footer-menu-container">
            <ul class="menu">
              <li class="menu-item"><a href="index.html" aria-current="page">Home</a></li>
              <li class="menu-item"><a href="blog.html">Blog</a></li>
              <li class="menu-item"><a href="careers.html">Careers</a></li>
              <li class="menu-item"><a href="clients.html">Clients</a></li>
              <li class="menu-item"><a href="artificial-intelligence.html">Service</a></li>
            </ul>
          </div>
        </div> -->
        <div class="col-lg-4 col-md-6 col-sm-6 mt-lg-0 mt-4">
          <h4 class="footer-title iq-rmt-30">Contact us</h4>
          <ul class="iq-contact">
            <li><a href="tel:{{ contactInfo.contactNumber }}"><i class="fa fa-phone"></i><span>{{ contactInfo.contactNumber }}</span></a></li>
            <li><a href="mailto:{{ contactInfo.email }}"><i class="fa fa-envelope"></i><span>{{ contactInfo.email }}</span></a></li>
            <li></li>
            <li><a><i class="fa fa-home"></i>
              <span><strong>{{ contactInfo.company }}</strong><br>{{ contactInfo.address }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ul class="d-flex justify-content-between align-items-center mb-0">
            <li class="list-inline-item"><a href="https://www.arka11.com/about-us" target="_blank"><img class="" height="80" width="150" src="../../../assets/images/about-us/startup_logo.jpg"></a></li>
            <li class="list-inline-item"><a href="https://www.india.gov.in/spotlight/government-e-marketplace-procurement-made-smart" target="_blank"><img class="" height="80" width="150" src="../../../assets/images/about-us/Gem-logo.jpg"></a></li>
            <li class="list-inline-item"><a href="https://www.iafindia.com/mr-ashok-chowdary/" target="_blank"><img class="" height="60" width="100" src="../../../assets/images/about-us/Logo-IAF.jpg"></a></li>
            <li class="list-inline-item"><a href="https://startup.siliconindia.com/ranking/10-best-green-energy-startups-2021-rid-1197.html" target="_blank"><img class="" height="80" width="150" src="../../../assets/images/about-us/startupcity-logo.jpg"></a></li>
            <li class="list-inline-item"><a href="https://www.arka11.com/about-us" target="_blank"><img class="" height="80" width="150" src="../../../assets/images/about-us/nmims-horizontal.jpg"></a></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Address END -->
    <div class="copyright-footer">
      <div class="pt-3 pb-3">
        <div class="row flex-row-reverse justify-content-between">
          <div class="col-lg-12 col-md-12 text-lg-center text-md-center text-center"><span class="copyright">&copy; Copyright 2021, <a href="">Arka11 Renewable Solutions Pvt Ltd</a> All Rights Reserved.</span></div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- Footer End-->
