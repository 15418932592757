<!-- Our Services -->
<section class="pt-0">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 wow fadeInUp">
        <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
        <app-nav-tabs [navList]="navList"></app-nav-tabs>
      </div>
    </div>
  </div>
</section>
<!-- Our Services -->
