<div *ngFor="let port of portfolioList" class="iq-masonry-item item {{ port.class }}">
  <div class="iq-portfolio">
    <a href="{{ port.href }}/{{port.id}}" class="iq-portfolio-img">
      <img src="../../../../assets/images/portfolio/{{ port.img }}" class="attachment-post-thumbnail size-post-thumbnail wp-post-image port-box-dimentions" alt="">
    </a>
    <div class="iq-portfolio-content text-center">
      <div class="details-box clearfix">
        <div class="consult-details">
          <a href="{{ port.href }}/{{port.id}}">
            <h5 class="text-hover">{{ port.title }}</h5>
          </a>
          <p class="mb-0">{{ port.type }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
