<!-- START REVOLUTION SLIDER -->
<app-revolution-slider3></app-revolution-slider3>
<!-- END REVOLUTION SLIDER -->
<!-- MainContent -->
<div class="main-content">
  <!-- Choose-us -->
      <app-why-choose-us></app-why-choose-us>
  <!-- Choose-us -->
  <!--  card-section-->
      <app-card-section></app-card-section>
  <!--  card-section End-->
  <!-- About Us -->
      <app-about-us></app-about-us>
  <!-- About Us End-->
    <!--Nav tab -->
      <app-nav-tabs></app-nav-tabs>
  <!-- Nav tab -->
  <!-- client counter  -->
      <!-- <app-client-counter></app-client-counter> -->
  <!-- client counter End -->
      <app-service></app-service>
  <!-- PROCESS -->
       <app-process></app-process>
  <!-- PROCESS  End-->
        <app-calculator-section></app-calculator-section>
  <!-- Our Testimonials -->
       <!-- <app-our-testimonials></app-our-testimonials> -->
  <!-- Our Testimonials End -->
  <!-- Blog -->
      <!-- <app-our-blog></app-our-blog> -->
  <!-- Blog End-->
  <!--  Partner-->
      <!-- <app-our-partner></app-our-partner> -->
  <!--  Partner End-->
</div>
