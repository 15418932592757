<!-- Why Choose Us -->
<section>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 pr-3">
        <div class="title-box wow fadeInUp mb-0 text-center text-lg-left border-right ">
          <span class="title-design">{{ lists.title }}</span>
          <h2>{{ lists.subtitle }}</h2>
        </div>
      </div>
      <div class="col-lg-8 align-self-center">
        <p>{{ lists.desc }}</p>
      </div>
    </div>
  </div>
</section>
