<section class="iq-bg-over">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 wow fadeInUp">
        <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
      </div>
    </div>
    <app-testimonials></app-testimonials>
  </div>
</section>
