<div class="owl-carousel owl-loaded owl-drag" data-dots="false" data-nav="false" data-items="3" data-items-laptop="3" data-items-tab="2" data-items-mobile="1" data-items-mobile-sm="1" data-autoplay="true" data-loop="true">
<div *ngFor="let lists of List"  class="item">
  <div class="iq-blog-box  wow fadeInUp">
    <div class="iq-blog-image clearfix">
      <img src="../.././../../assets/images/about-us/{{ lists.img }}" class="img-fluid center-block" alt="blog-image1">
      <ul class="iq-blogtag">
        <li><a href="blog.html">{{ lists.name }}</a> </li>
      </ul>
    </div>
    <div class="iq-blog-detail">
      <div class="iq-blog-meta">
        <ul>
          <li class="list-inline-item">  <a href="blog-details.html" rel="bookmark"> <time class="entry-date published updated" datetime="2019-09-26T06:49:49+00:00" >{{ lists.time }} </time> </a> </li>
        </ul>
      </div>
      <div class="blog-title">
        <a href="blog-details.html">
          <h4>{{ lists.title }}</h4>
        </a>
      </div>
      <p>{{ lists.desc }}</p>
      <div class="blog-button"><a class="button-link" href="blog-details.html">Read More<i class="fa fa-angle-right" aria-hidden="true"></i></a></div>
    </div>
  </div>
</div>
</div>
