<!-- How it Works -->
<section class="xamin-custom-bg">
  <div class="container">
    <div class="row ">
      <div class="col-md-12">
        <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
      </div>
    </div>
    <div class="row ">
      <div class="col-lg-4 col-md-4 col-sm-12">
        <app-xamin-circle [circleData]="circle1Data"></app-xamin-circle>
      </div>
      <div class=" col-lg-4 col-md-4 col-sm-12 mt-4 mt-md-0">
        <app-xamin-circle [circleData]="circle2Data"></app-xamin-circle>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 mt-4 mt-md-0">
        <app-xamin-circle [circleData]="circle3Data"></app-xamin-circle>
      </div>
    </div>
  </div>
</section>
<!-- How it Works End -->
