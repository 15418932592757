<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 wow fadeInUp">
    <div class="pagination justify-content-center">
      <nav aria-label="Page navigation">
        <ul class='page-numbers'>
          <li> <span aria-current='page' class='page-numbers current'>1</span> </li>
          <li> <a class='page-numbers' href='#?paged=2'>2</a> </li>
          <li> <a class="next page-numbers" href="#?paged=2'"> <span aria-hidden="true">Next page</span> </a> </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
