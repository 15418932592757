<!-- Header component start-->
<app-header [logoImg]="logoImage" [navItemList]="navItems"></app-header>
<!-- Header component end-->

<!-- Loader component start-->
<app-loader></app-loader>
<!-- Loader component end-->
<router-outlet></router-outlet>
<!-- Scroll Top component start -->
<app-scroll-top></app-scroll-top>
<!-- Scroll Top component end -->

<!-- Footer start -->
<app-footer [logoImg]="logoImageFooter" [contactInfo]="contactInfo"></app-footer>
<!-- Footer end -->