<section class="pt-0">
  <div class="container">
    <div class="row">
      <div *ngFor="let list of cardData" class="col-lg-4 col-sm-12 h-100">
        <div class="xamin-services  mb-0 wow fadeInUp text-left">
          <div class="services-detail">
            <h4 class="mb-1">{{ list.title }}</h4>
            <p class="mb-0">{{ list.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
