<div class="custom-tab">
  <ul class="nav nav-pills mb-5 text-center" id="pills-tab" role="tablist">
    <li class="nav-item" *ngFor="let nav of navList">
      <a class="nav-link {{ nav.active !== undefined && nav.active ? 'show active' : '' }}" data-toggle="pill" href="{{ '#' + nav.id }}" role="tab" aria-selected="true">
        <img src="../../../../assets/images/services/{{ nav.img }}" alt="tab-image" class="img-fluid">
        <h5 class="tab-title">{{ nav.title }}</h5>
      </a>
    </li>
  </ul>
  <div class="show_content">
    <div *ngFor="let nav of navList" id="{{ nav.id }}" class="tab-pane fade {{ nav.active !== undefined && nav.active ? 'show active' : '' }}">
      <ng-template [ngIf]="nav.id === 'tab-big-data' || nav.id === 'tab-analytical-ai'">
        <div class="row pl-lg-5 pr-lg-5">
          <div class="col-sm-12">
            <h4 class="custom_heading mb-2">Choose Your Software Development Service by Category </h4>
            <div class="text-left mb-3">
              <p>Tame your big data through robust solutions that empower data collection, storage, processing and
                analysis.</p>
            </div>
          </div>
        </div>
        <div class="row pl-lg-5 pr-lg-5">
          <div class="col-sm-4">
            <app-check-list [lists]="nav.chkList1"></app-check-list>
          </div>
          <div class="col-sm-4">
            <app-check-list [lists]="nav.chkList2"></app-check-list>
          </div>
          <div class="col-sm-4">
            <app-check-list [lists]="nav.chkList3"></app-check-list>
          </div>
        </div>
        <div class="row pl-lg-5 pr-lg-5">
          <div class="col-sm-12">
            <div class=" text-left mt-4 "><a class="button button-icon" href="services.html"><span class="btn-effect"> View all Services</span></a>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="nav.id === 'tab-machine-learning' || nav.id === 'tab-computer-vision'">
        <div class="row pl-lg-5 pr-lg-5">
          <div class="col-sm-6">
            <h4 class="custom_heading mb-2">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</h4>
            <div class="text-left no-margin">
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable. </p>
            </div>
            <div class=" text-left mt-4 mb-lg-0 mb-md-0 mb-4 "> <a class="button button-icon" href="services.html"><span class="btn-effect"> Read More</span></a></div>
          </div>
          <div class="col-sm-6">
            <h4 class="custom_heading mb-3">Dignissimos ducimus qui blanditiis</h4>
            <app-check-list [lists]="nav.chkList"></app-check-list>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
