import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-partner',
  templateUrl: './our-partner.component.html',
})
export class OurPartnerComponent implements OnInit {

  hover : boolean = false;

  constructor() { }

  ngOnInit() {
  }
}
