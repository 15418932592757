<!-- Breadcrumb -->
<div class="text-left iq-breadcrumb-one iq-bg-over black">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-8 text-left align-self-center">
        <nav aria-label="breadcrumb" class="text-left">
          <h2 class="title">{{ data.title }}</h2>
          <ol class="breadcrumb main-bg">
            <li class="breadcrumb-item"><a routerLink="/">Home</a> </li>
            <li class="breadcrumb-item active">{{ data.title }}</li>
          </ol>
        </nav>
      </div>
      <!-- <div class="col-lg-4 col-md-4 col-sm-12 text-right wow fadeInRight"> <img src="../.././../../assets/images/about-us/{{ data.img }}" class="img-fluid float-right" alt="banner"> </div> -->
    </div>
  </div>
</div>
<!-- Breadcrumb End -->
