<section class="position-relative pt-0">
    <ng-template *ngIf="List.isOuter">
      <img src="../../../../assets/images/about-us/{{ List.img }}" class="about-box-img" alt="">
    </ng-template>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <ng-template [ngIf]="!List.isOuter">
            <img src="../../../../assets/images/calculator/{{ List.img }}" alt="">
          </ng-template>
        </div>
        <div class="col-lg-6 col-sm-12 wow fadeInUp align-items-center">
          <app-title-section [titleSectionProp]="titleSectionProp"></app-title-section>
          <ng-template *ngIf="List.ischkList!==undefined">
            <app-check-list [lists]="List.chkList"></app-check-list>
          </ng-template>
          <ng-template *ngIf="List.ischkList===undefined">
            <p> {{ List.desc }}</p>
          </ng-template>
          <div class="text-left mt-5">
            <a class="button button-icon" routerLink="/solar-calculator"><span class="btn-effect">Calculate Your Savings</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  