<div class="iq-banner">
  <!-- START xamin REVOLUTION SLIDER 6.1.0 -->
  <p class="rs-p-wp-fix"></p>
  <rs-module-wrap id="rev_slider_3_1_wrapper" data-alias="xamin-3" data-source="gallery" style="background:transparent;padding:0;margin:0px auto;margin-top:0;margin-bottom:0;">
    <rs-module id="rev_slider_3_1" class=" rs-ov-hidden" style="display:none;" data-version="6.1.0">
      <rs-slides>
        <rs-slide data-key="rs-3" data-title="Slide" data-anim="ei:d,d,d,d,d,d,d,d,d;eo:d,d,d,d,d,d,d,d,d;s:1000ms,d,d,d,d,d,d,d,d;r:0,0,0,0,0,0,0,0,0;t:fade,grayscale,blurlight,blurstrong,fade,crossfade,fadethroughlight,fadethroughdark,fadethroughtransparent;sl:d,d,d,d,d,d,d,d,d;" data-firstanim="t:fade;sl:12;">
          <img src="/assets/revslider/assets_slider3/dummy.png" title="bg" width="856" height="500" data-lazyload="./assets/revslider/assets_slider3/bg.png" data-bg="f:100% 100%;" data-parallax="2" class="rev-slidebg" data-no-retina>
          <!--
                                      --><h2
          id="slider-3-slide-3-layer-1"
          class="rs-layer"
          data-type="text"
          data-color="#313e5b"
          data-rsp_ch="on"
          data-xy="x:l,l,l,c;xo:69px,15px,37px,-1px;yo:221px,141px,40px,30px;"
          data-text="s:48,36,42,30;l:65,65,70,55;a:inherit,inherit,inherit,center;"
          data-dim="w:auto,auto,auto,auto;"
          data-layeronlimit="on"
          data-frame_0="y:-50;"
          data-frame_1="st:600;sp:1000;sR:600;"
          data-frame_999="o:0;tp:600;st:w;sR:7400;"
          style="z-index:8;font-family:Lato;text-transform:capitalize;"
        >
        Going Green with Renewable <br> Energy <br>
        </h2><!--

							--><p
          id="slider-3-slide-3-layer-3"
          class="rs-layer"
          data-type="text"
          data-color="#525f81"
          data-rsp_ch="on"
          data-xy="x:l,l,l,c;xo:67px,15px,40px,5px;y:m;yo:56px,15px,-180px,-106px;"
          data-text="s:16,16,18,17;l:30;a:inherit,inherit,inherit,center;"
          data-frame_0="y:-50;"
          data-frame_1="st:900;sp:1000;sR:900;"
          data-frame_999="o:0;tp:600;st:w;sR:7100;"
          style="z-index:9;font-family:Karla;"
        >Our goal is to make the availability of cleaner sources of <br> 
          energy as easy as possible at an affordable rate for not <br> 
          only businesses & industries but also for regular homes.
        </p><!--

							--><rs-layer
          id="slider-3-slide-3-layer-11"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:927px,721px,226px,98px;yo:118px,82px,332px,228px;"
          data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
          data-dim="w:500px,298px,338px,350px;h:400px,245px,338px,288px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2400;sp:1000;sR:2400;"
          data-frame_999="o:0;st:w;sR:5600;"
          style="z-index:13;font-family:Roboto;"
        ><img src="./assets/revslider/assets_slider3/dummy.png" width="790" height="632" data-lazyload="./assets/revslider/assets_slider3/panel.png" data-no-retina>
        </rs-layer><!--

							--><rs-layer
          id="slider-3-slide-3-layer-25"
          class="rs-pxl-1"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:961px,626px,200px,165px;yo:140px,165px,411px,220px;"
          data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
          data-dim="w:137px,93px,97px,90px;h:137px,93px,97px,90px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2100;sp:1000;sR:2100;"
          data-frame_999="o:0;st:w;sR:5900;"
          data-tloop="u:true;"
          data-loop_999="sX:0.8;sY:0.8;o:0.6;sp:2000;st:500;e:Power0.easeInOut;yys:t;yyf:t;"
          style="z-index:12;font-family:Roboto;"
        ><img src="./assets/revslider/assets_slider3/dummy.png" width="364" height="246" data-lazyload="./assets/revslider/assets_slider3/industry.png" data-no-retina>
        </rs-layer>

        <!--

							--><rs-layer
          id="slider-3-slide-3-layer-30"
          class="rs-pxl-1"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:701px,701px,58px,30;yo:280px,347px,489px,311px;"
          data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
          data-dim="w:281px,145px,121px,154px;h:199px,112px,103px,122px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:2700;sp:1000;sR:2700;"
          data-frame_999="o:0;st:w;sR:5300;"
          data-tloop="u:true;"
          data-loop_999="sX:0.8;sY:0.8;o:0.6;sp:2000;e:Power2.easeInOut;yys:t;yyf:t;"
          style="z-index:14;font-family:Roboto;"
        ><img src="./assets/revslider/assets_slider3/dummy.png" width="261" height="151" data-lazyload="./assets/revslider/assets_slider3/building.png" data-no-retina>
        </rs-layer><!--

							--><rs-layer
          id="slider-3-slide-3-layer-31"
          class="rs-pxl-2"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:710px,598px,89px,20px;yo:497px,291px,649px,481px;"
          data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
          data-dim="w:161px,95px,71px,94px;h:129px,72px,53px,68px;"
          data-frame_0="sX:0.9;sY:0.9;"
          data-frame_1="st:3000;sp:1000;"
          data-frame_999="o:0;st:w;sR:5000;"
          data-loop_999="sX:1.2;sY:1.2;o:0.6;sp:2000;st:1000;e:Power0.easeInOut;yys:t;yyf:t;"
          style="z-index:15;font-family:Roboto;"
        ><img src="./assets/revslider/assets_slider3/dummy.png" width="387" height="292" data-lazyload="./assets/revslider/assets_slider3/house.png" data-no-retina>
        </rs-layer><!--

							--><rs-layer
          id="slider-3-slide-3-layer-32"
          class="rs-pxl-1"
          data-type="image"
          data-rsp_ch="on"
          data-xy="xo:1011px,876px,280px,195px;yo:564px,349px,703px,505px;"
          data-text="w:normal;s:20,14,10,6;l:0,18,13,8;"
          data-dim="w:137px,93px,97px,100px;h:137px,93px,97px,100px;"
          data-frame_0="y:-50,-36,-26,-16;"
          data-frame_1="st:3300;sp:1000;"
          data-frame_999="o:0;st:w;sR:4700;"
          data-tloop="u:true;"
          data-loop_999="sX:0.8;sY:0.8;o:0.6;sp:2000;st:1000;e:Power0.easeInOut;yys:t;yyf:t;"
          style="z-index:16;font-family:Roboto;"
        ><img src="./assets/revslider/assets_slider3/dummy.png" width="206" height="122" data-lazyload="./assets/revslider/assets_slider3/farm.png" data-no-retina>
        </rs-layer><!--
-->						</rs-slide>
      </rs-slides>
      <rs-static-layers><!--
					--></rs-static-layers>
      <rs-progress class="rs-bottom" style="visibility: hidden !important;"></rs-progress>
    </rs-module>
  </rs-module-wrap>
  <!-- END REVOLUTION SLIDER -->
</div>
