

<div class="row mb-5">
  <div class="col-md-12 wow fadeInUp">
    <div class="owl-carousel client-logo owl-loaded owl-drag" data-dots="false" data-nav="false" data-items="6" data-items-laptop="4" data-items-tab="3" data-items-mobile="2" data-items-mobile-sm="1" data-autoplay="true" data-loop="true" data-margin="30">
      <div *ngFor="let lists of List" class="item">
        <ng-template [ngIf]="hover">
          <div class="clients-box hover-effct">
            <img class="img-fluid client-img" src="../../../../assets/images/partners/{{ lists.img }}" alt="client-image" />
          </div>
        </ng-template>
        <ng-template [ngIf]="!hover">
          <div class="clients-box ">
            <img class="img-fluid client-img" src="../../../../assets/images/partners/{{ lists.img }}" alt="client-image" />
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
